import {
  Accordion,
  ADSInputEvent,
  Button,
  Col,
  Flex,
  Form,
  object,
  Row,
  string,
  TextField
} from '@appliedsystems/applied-design-system';
import React, { useMemo, useState } from 'react';
import { currencyMap } from '../../constants/constants';
import { useHppAccordion } from '../../hooks/useAccordionState';
import { useIsEligibleForFlowV2 } from '../../hooks/useIsEligibleforHppCheckoutUi';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { ConfirmationPage } from '../ConfirmationPage/ConfirmationPage';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { HppStep } from '../HostedPaymentPageContainer/enums';
import { HppData } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { Layout } from '../Layout/Layout';
import { PaymentMethodContainer } from '../PaymentMethodContainer';
import { availablePaymentMethods } from '../PaymentMethodSelection/PaymentMethodSelection';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import classes from './CheckoutContainer.module.scss';
import { CheckoutContainerSkeleton } from './CheckoutContainerSkeleton';
import { parseRedirectUrl } from './redirectUrl';
import { useCheckoutStore } from './useCheckoutStore';

export const CheckoutContainer = () => {
  // State
  const [paymentIsSuccess, setPaymentSuccess] = useState(false);

  // Hooks
  const { hppData, paymentMethodConfig, setHppData } = useHppDataStore();
  const locale = Locale.useContainer().locale;
  const { t } = usePaymentsTranslation();
  const {
    isLoading: checkoutIsLoading,
    errorMessage,
    updateCustomerInfo,
    isSubmitting: updatingCustomerInfo,
    pspSession
  } = useCheckoutStore();

  useIsEligibleForFlowV2(pspSession?.tenantId, '/flow/checkout');

  const { accordionStates, updateAccordions, checkDependencies } =
    useHppAccordion<typeof HppStep, 'ACCOUNT_INFORMATION' | 'PAYMENT_METHOD'>({
      ACCOUNT_INFORMATION: {
        isOpen: true,
        isValid: false,
        dependsOn: []
      },
      PAYMENT_METHOD: {
        isOpen: false,
        isValid: false,
        dependsOn: [HppStep.ACCOUNT_INFORMATION]
      }
    });
  // Helpers
  const renderHppStep = (
    hppStep: HppStep.ACCOUNT_INFORMATION | HppStep.PAYMENT_METHOD,
    componentToRender: React.ReactNode
  ) => {
    const stepIndex = [
      HppStep.ACCOUNT_INFORMATION,
      HppStep.PAYMENT_METHOD
    ].findIndex((e) => e === hppStep);

    return (
      <div
        className={`${classes.accordion} ${
          accordionStates[hppStep].isValid ? classes.accordionSuccess : ''
        }`}
        key={hppStep}
      >
        <Accordion
          title={`${stepIndex + 1}. ${t(hppStep)}`}
          open={accordionStates[hppStep]?.isOpen}
          onOpenChange={(nextState) => {
            updateAccordions({
              [hppStep]: {
                isOpen:
                  nextState === false ? nextState : checkDependencies(hppStep),
                isValid: accordionStates[hppStep].isValid
              }
            });
          }}
          testId={`${hppStep.toLowerCase()}-accordion`}
        >
          <Row>
            <Col xs={12}>{componentToRender}</Col>
          </Row>
        </Accordion>
      </div>
    );
  };

  const handlePaymentSuccess = () => {
    // We're keeping V1 functionality
    // This was a result of PAY-2619
    const sessionDetails = pspSession!;
    const returnUrl = sessionDetails.returnUrl;
    if (returnUrl) {
      setTimeout(() => {
        window.location.href = parseRedirectUrl({
          returnUrl,
          hppData,
          sessionDetails
        });
      }, 3000);
    }

    setPaymentSuccess(true);
  };

  const updateData = (
    e: ADSInputEvent,
    key: keyof Pick<
      HppData,
      'firstName' | 'lastName' | 'userEmail' | 'businessName'
    >
  ) => {
    setHppData({
      paymentMethod: undefined,
      paymentTotal: 0,
      paymentFee: 0,
      [key]: e.target.value
    });
    updateAccordions({
      [HppStep.ACCOUNT_INFORMATION]: {
        isValid: false,
        isOpen: true
      },
      [HppStep.PAYMENT_METHOD]: {
        isValid: false,
        isOpen: false
      }
    });
  };

  // Computed props
  const formSchema = useMemo(
    () =>
      object<
        Pick<HppData, 'firstName' | 'lastName' | 'userEmail' | 'businessName'>
      >({
        firstName: string().label(t('FIRST_NAME')).required(),
        lastName: string().label(t('LAST_NAME')).required(),
        userEmail: string().label(t('EMAIL')).email().required(),
        businessName: string().label(t('BUSINESS_NAME')).optional()
      }).default({
        firstName: hppData.firstName,
        lastName: hppData.lastName,
        userEmail: hppData.userEmail,
        businessName: hppData.businessName
      }),
    [
      hppData.businessName,
      hppData.firstName,
      hppData.lastName,
      hppData.userEmail,
      t
    ]
  );

  return (
    <>
      <Layout
        accordions={
          <>
            {errorMessage ? (
              <ErrorAlert errorMessage={errorMessage} />
            ) : (
              <>
                {renderHppStep(
                  HppStep.ACCOUNT_INFORMATION,
                  <Form
                    debug={false}
                    schema={formSchema}
                    onSubmit={async () => {
                      const updatedSuccess = await updateCustomerInfo(hppData);
                      if (updatedSuccess) {
                        const defaultMethod = availablePaymentMethods
                          .map((key) => ({ key, ...paymentMethodConfig[key] }))
                          .find((c) => c.allowed);
                        if (defaultMethod) {
                          const { paymentTotal, paymentFee } =
                            getAmountWithFees(
                              defaultMethod.key,
                              hppData.paymentAmount,
                              paymentMethodConfig[defaultMethod.key].fee,
                              currencyMap[locale]
                            );

                          setHppData({
                            paymentMethod: defaultMethod.key,
                            paymentFee,
                            paymentTotal
                          });
                        }

                        updateAccordions({
                          [HppStep.ACCOUNT_INFORMATION]: {
                            isValid: true,
                            isOpen: false
                          },
                          [HppStep.PAYMENT_METHOD]: {
                            isValid: false,
                            isOpen: true
                          }
                        });
                      }
                    }}
                  >
                    {checkoutIsLoading ? (
                      <CheckoutContainerSkeleton />
                    ) : (
                      <Flex className={classes.flexColumn}>
                        <Flex className={classes.flexRow}>
                          <TextField
                            className="flex-item"
                            name={'firstName'}
                            onChange={(e) => updateData(e, 'firstName')}
                          />
                          <TextField
                            className="flex-item"
                            name={'lastName'}
                            onChange={(e) => updateData(e, 'lastName')}
                          />
                        </Flex>
                        <Flex className={classes.flexRow}>
                          <TextField
                            className="flex-item"
                            name={'userEmail'}
                            onChange={(e) => updateData(e, 'userEmail')}
                          />
                          <TextField
                            className="flex-item"
                            name={'businessName'}
                            onChange={(e) => updateData(e, 'businessName')}
                          />
                        </Flex>
                        <Flex className="flex-align-end">
                          <Button
                            className="brandPrimaryButton"
                            type="primary"
                            submit
                            isLoading={updatingCustomerInfo}
                            disabled={updatingCustomerInfo}
                          >
                            {t('CONTINUE_TO_PAYMENT')}
                          </Button>
                        </Flex>
                      </Flex>
                    )}
                  </Form>
                )}
                {renderHppStep(
                  HppStep.PAYMENT_METHOD,
                  <PaymentMethodContainer
                    onPaymentSuccess={handlePaymentSuccess}
                    onBack={() => {
                      updateAccordions({
                        [HppStep.ACCOUNT_INFORMATION]: {
                          isValid: true,
                          isOpen: true
                        },
                        [HppStep.PAYMENT_METHOD]: {
                          isValid: false,
                          isOpen: true
                        }
                      });
                    }}
                  />
                )}
              </>
            )}
          </>
        }
        orderSummary={<SummaryCard isConfirmation={false} />}
        confirmationComponent={<ConfirmationPage merchantName={''} />}
        showConfirmationComponent={paymentIsSuccess}
        // The code below can be leveraged to display an error to the user without hitting flow/error/
        // confirmationComponent={
        //   errorMessage ? (
        //     <>
        //       <ErrorAlert errorMessage={errorMessage} closeable={false} />
        //     </>
        //   ) : (
        //     <>
        //       <ConfirmationPage merchantName={''} />
        //     </>
        //   )
        // }
        // showConfirmationComponent={paymentIsSuccess || !!errorMessage}
      ></Layout>
    </>
  );
};
