import {
  AppliedProductId,
  HostedPaymentSessionRequest,
  PaymentAttribute
} from '@appliedsystems/payments-core';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { ErrorMessage } from '../ErrorAlert/ErrorAlert';
import { availablePaymentMethods } from '../PaymentMethodSelection/PaymentMethodSelection';
import { PayBySelection } from './enums';
import { FullInvoiceGroup, HppData, RetrievedInvoices } from './types';
import { useHppDataStore } from './useHppData';

export type HppSession = {
  sessionData?: string;
  expiresAt: Date;
  sessionId: string;
  pspSessionId: string;
  paymentFlowSessionId: string;
  appliedProductId: AppliedProductId;
};
// todo PAY-2239, currently this is the same as HostedPaymentSessionPayload.
// add needed info from PspSessionResponsePayload to create a new combined type

const useHppSession = () => {
  const locale = Locale.useContainer().locale;
  const { pathname } = useLocation();
  const { setHppData, paymentMethodConfig } = useHppDataStore();

  const [hppSessionRequest, setHppSessionRequest] =
    useState<HostedPaymentSessionRequest>();
  const [hppSession, setHppSession] = useState<HppSession>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const initializeHppPaymentSession = async (
    hppToken: string,
    hppData: HppData,
    retrievedInvoices: RetrievedInvoices,
    selectedInvoices: FullInvoiceGroup[]
  ): Promise<boolean> => {
    if (pathname.includes('checkoutv2')) {
      // Should not allow a session to be iniated from the checkout page
      return false;
    }

    try {
      const selectedInvoiceNumbers = selectedInvoices.map(
        (e) => e.invoiceNumber
      );

      const transactions = retrievedInvoices
        .filter((e) => selectedInvoiceNumbers.includes(e.invoiceNumber))
        .map((invoice) => {
          return {
            id: invoice.transactionId,
            amount: {
              units: Number(invoice.amountDue.units),
              partialUnits: Number(invoice.amountDue.partialUnits)
            }
          };
        });

      const selectedInvoiceItems = selectedInvoices.flatMap(
        (invoice) => invoice.invoiceItems
      );

      const splitPaymentAttributes =
        hppData.payBy === PayBySelection.INVOICE
          ? selectedInvoiceItems.map((invoice) => {
              return [
                {
                  name: PaymentAttribute.InvoiceNumber,
                  value: invoice.invoiceNumber
                },
                {
                  name: PaymentAttribute.AccountNumber,
                  value: hppData.accountCode
                },
                {
                  name: PaymentAttribute.PaymentAmount,
                  value: invoice.amountDue.toString()
                },
                {
                  name: PaymentAttribute.Description,
                  value: invoice.description
                }
              ];
            })
          : [];

      const paymentAttributes: {
        name: PaymentAttribute;
        value: string;
      }[] = [];

      if (
        [PayBySelection.AMOUNT, PayBySelection.NONE].includes(hppData.payBy)
      ) {
        if (hppData.invoiceNumber) {
          paymentAttributes.push({
            name: PaymentAttribute.InvoiceNumber,
            value: hppData.invoiceNumber
          });
        }
        if (hppData.policyNumber) {
          paymentAttributes.push({
            name: PaymentAttribute.PolicyNumber,
            value: hppData.policyNumber
          });
        }
        if (hppData.accountCode) {
          paymentAttributes.push({
            name: PaymentAttribute.AccountNumber,
            value: hppData.accountCode
          });
        }
        if (hppData.paymentAmount) {
          paymentAttributes.push({
            name: PaymentAttribute.PaymentAmount,
            value: hppData.paymentAmount.toString()
          });
        }
      }

      const data = {
        customer: {
          firstName: hppData.firstName,
          lastName: hppData.lastName,
          email: hppData.userEmail,
          businessName: hppData.businessName
        },
        locale,
        amount: hppData.paymentAmount,
        paymentDescription: hppData.paymentDescription,
        workflow: hppData.paymentWorkflow,
        paymentAttributes,
        splitPaymentAttributes,
        ...(hppData.clientId && {
          epicInvoiceMeta: {
            clientId: hppData.clientId,
            ...(transactions.length && {
              transactions
            })
          }
        })
      };

      // Update the session data
      setHppSessionRequest(data);

      // Set loading state to true
      setIsSubmitting(true);
      setErrorMessage(undefined);

      // Determine if we have changed the payment data
      if (
        hppSessionRequest &&
        JSON.stringify(hppSessionRequest) === JSON.stringify(data) &&
        hppSession
      ) {
        // Don't make a duplicate session if user submitted the same data twice
        setIsSubmitting(false);
        return true;
      }

      setHppSession(undefined);

      const response = await ApiClient.getInstance().initHppSession(
        hppToken,
        data
      );

      // Stop loading once the API call is done
      setIsSubmitting(false);

      if (response.status === 'ok') {
        setHppSession(response.data);

        let nextHppData: Partial<HppData> = {};

        const defaultMethod = availablePaymentMethods
          .map((key) => ({ key, ...paymentMethodConfig[key] }))
          .find((c) => c.allowed);
        if (defaultMethod) {
          const { paymentTotal, paymentFee } = getAmountWithFees(
            defaultMethod.key,
            hppData.paymentAmount,
            paymentMethodConfig[defaultMethod.key].fee,
            currencyMap[locale]
          );

          nextHppData = {
            ...nextHppData,
            paymentMethod: defaultMethod.key,
            paymentFee,
            paymentTotal
          };
        }

        setHppData(nextHppData);
        return true;
      }

      if (response.type === 'network' || response.status > 500) {
        setErrorMessage(['GET_HPP_SESSION_ERROR_NETWORK']);
      } else if (response.status === 500) {
        setErrorMessage([
          'GET_HPP_SESSION_ERROR_INTERNAL',
          { id: response.traceId }
        ]);
      } else {
        setErrorMessage([
          'GET_HPP_SESSION_ERROR_UNKNOWN',
          { id: response.traceId }
        ]);
      }

      // Failed to initialize session
      return false;
    } catch (err) {
      console.error('Failed to get HPP session with error', err);
      setErrorMessage(['GET_HPP_SESSION_ERROR_UNKNOWN']);
      setIsSubmitting(false);
      return false;
    }
  };

  return {
    hppSession,
    setHppSession,
    isSubmitting,
    initializeHppPaymentSession,
    errorMessage
  };
};

export const HppSessionStore = createContainer(useHppSession);
export const useHppSessionStore = () => {
  return HppSessionStore.useContainer();
};
