import { Card, Icon } from '@appliedsystems/applied-design-system';
import { toIntlFormat } from '@appliedsystems/payments-core';
import React from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import classes from './PaymentOptionCard.module.scss';

type PaymentOptionCardProps = {
  title: string;
  subtotal: number;
  total: number;
  fee: number;
  showFeeAsPercentage: boolean;
  icon: string;
  isActiveCard: boolean;
  tooltip?: string;
  choosePaymentMethod: () => void;
};

export const PaymentOptionCard = ({
  icon,
  title,
  subtotal,
  total,
  fee,
  showFeeAsPercentage,
  isActiveCard,
  tooltip,
  choosePaymentMethod
}: PaymentOptionCardProps) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];

  const feeAsPercentage = ((fee / (subtotal || 1.0)) * 100).toFixed(1);

  const getIntlFormattedAmount = (amount: number) =>
    toIntlFormat(
      {
        amount,
        currencyCode
      },
      locale
    );

  return (
    <Card className={`${classes.card} ${isActiveCard ? classes.active : ''}`}>
      <section onClick={choosePaymentMethod}>
        <div className={classes.title}>
          <img src={icon} alt={title} />
          <span>{title}</span>
          {tooltip && (
            <Icon
              icon="ICircleIcon"
              size={18}
              tooltip={{
                content: tooltip,
                trigger: 'touchstart click mouseenter'
              }}
              className={classes.paymentMethodTooltip}
            />
          )}
        </div>
        <div className={classes.cardItems}>
          <div className={classes.cardItem}>
            <span>{t('SUBTOTAL')}</span>
            <span>{getIntlFormattedAmount(subtotal)}</span>
          </div>
          <div className={classes.cardItem}>
            <span>{t('CONVENIENCE_FEE')}</span>
            <span>
              {!showFeeAsPercentage
                ? `${getIntlFormattedAmount(fee)}`
                : `${feeAsPercentage}%`}
            </span>
          </div>
        </div>
      </section>
    </Card>
  );
};
