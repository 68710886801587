import {
  ErrorCode,
  PaymentAttribute,
  PaymentMethod,
  PspSessionResponsePayload,
  toMinorUnits,
  toUnits
} from '@appliedsystems/payments-core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { Locale } from '../../store/Locale';
import { ErrorMessage } from '../ErrorAlert/ErrorAlert';
import { PayBySelection } from '../HostedPaymentPageContainer/enums';
import {
  FullInvoiceGroup,
  HppData,
  SelectedInvoice
} from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { useHppSessionStore } from '../HostedPaymentPageContainer/useHppSession';

const useCheckoutSession = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const [agencyToken, setAgencyToken] = useState<string>();
  const [pspSession, setPspSession] = useState<PspSessionResponsePayload>();

  const { pathname } = useLocation();
  const { locale } = Locale.useContainer();
  const location = useLocation();
  const { setHppSession } = useHppSessionStore();
  const { setHppData, setSelectedInvoices, setPaymentMethodConfig } =
    useHppDataStore();

  const sessionToken = location.hash.replace('#session=', '');

  useEffect(() => {
    // Do not call this outside of checkoutv2
    if (!pathname.includes('checkoutv2')) {
      return;
    }

    if (!sessionToken) {
      setPspSession(undefined); // Clear session data if call fails
      return;
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setErrorMessage(undefined);

        const response = await ApiClient.getInstance(
          sessionToken
        ).initPspSession();

        if (response.status === 'ok' && response.data) {
          const data = response.data;

          setAgencyToken(data.merchantAccountSettings.hostedPaymentPageToken);
          setPspSession(data);

          // Set the hpp session
          setHppSession({
            sessionId: data.sessionId,
            sessionData: data.sessionData,
            pspSessionId: data.pspSessionId,
            paymentFlowSessionId: sessionToken,
            appliedProductId: data.appliedProductId,
            expiresAt: data.expiresAt
          });

          // Then set the hpp data & invoices (if needed)
          const isInvoice = (data.splitPaymentAttributes?.length || 0) > 0;
          setHppData({
            payBy: isInvoice ? PayBySelection.INVOICE : PayBySelection.AMOUNT,
            paymentAmount: data.amount,
            paymentDescription: data.paymentDescription,
            firstName: data.customerFirstName,
            lastName: data.customerLastName,
            userEmail: data.customerEmail,
            businessName: data.customerBusinessName
          });

          if (isInvoice) {
            const fullInvoiceGroups: FullInvoiceGroup[] = [];

            data.splitPaymentAttributes!.forEach((attrs) => {
              const invoiceItem: SelectedInvoice = {
                amountDue: 0,
                invoiceNumber: '',
                description: '',
                dueDate: '',
                itemNumber: ''
              };

              attrs.forEach((attr) => {
                if (attr.name === PaymentAttribute.InvoiceNumber)
                  invoiceItem.invoiceNumber = attr.value;
                if (attr.name === PaymentAttribute.PaymentAmount)
                  invoiceItem.amountDue = Number(attr.value);
                if (attr.name === PaymentAttribute.Description)
                  invoiceItem.description = attr.value;
                if (attr.name === PaymentAttribute.ItemNumber)
                  invoiceItem.itemNumber = attr.value;
              });

              const foundInvoice = fullInvoiceGroups.find(
                (invoice) => invoice.invoiceNumber === invoiceItem.invoiceNumber
              );
              if (foundInvoice) {
                foundInvoice.amountDueMinorUnits += invoiceItem.amountDue;
                foundInvoice.amountDue = `${toUnits(
                  foundInvoice.amountDueMinorUnits,
                  currencyMap[locale]
                )}`;
                foundInvoice.invoiceItems.push(invoiceItem);
              } else {
                fullInvoiceGroups.push({
                  invoiceNumber: invoiceItem.invoiceNumber,
                  dueDate: invoiceItem.dueDate,
                  amountDueMinorUnits: invoiceItem.amountDue,
                  amountDue: `${toUnits(
                    invoiceItem.amountDue,
                    currencyMap[locale]
                  )}`,
                  invoiceItems: [invoiceItem]
                });
              }
            });

            setSelectedInvoices(fullInvoiceGroups);
          }

          // Set payment method configuration based on the psp session
          setPaymentMethodConfig({
            [PaymentMethod.Ach]: {
              allowed:
                data.allowedPaymentMethods?.includes(PaymentMethod.Ach) ||
                false,
              fee: data.feeAch ?? 0
            },
            [PaymentMethod.Card]: {
              allowed:
                data.allowedPaymentMethods?.includes(PaymentMethod.Card) ||
                false,
              fee: toMinorUnits(
                ((data.feeCredit ?? 0) / data.amount) * 100,
                currencyMap[locale]
              )
            }
          });
        }

        if (response.status !== 'ok') {
          setPspSession(undefined); // Clear the session if the call fails
          const errorCode = response.additionalDetails?.[0].errorCode;

          if (response.type === 'network' || response.status > 500) {
            setErrorMessage(['GET_HPP_SESSION_ERROR_NETWORK']);
          } else if (response.status === 500) {
            setErrorMessage([
              'GET_HPP_SESSION_ERROR_INTERNAL',
              { id: response.traceId }
            ]);
          } else if (
            errorCode === ErrorCode.PaymentSessionExpired &&
            response.additionalData?.['hppLinkExpirationDays']
          ) {
            setErrorMessage([
              'ERROR_SESSION_EXPIRED',
              {
                paymentSessionExpirationDays:
                  response.additionalData['hppLinkExpirationDays']
              }
            ]);
          } else if (errorCode === ErrorCode.DuplicatePaymentRequest) {
            setErrorMessage(['ERROR_DUPLICATE_PAYMENT']);
          } else {
            setErrorMessage([
              'GET_HPP_SESSION_ERROR_UNKNOWN',
              { id: response.traceId }
            ]);
          }
        }
      } catch (err) {
        console.error('Failed to get PSP session with error', err);
        setErrorMessage(['GET_HPP_SESSION_ERROR_UNKNOWN']);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    locale,
    pathname,
    sessionToken,
    setHppData,
    setHppSession,
    setPaymentMethodConfig,
    setSelectedInvoices
  ]);

  const updateCustomerInfo = async (
    info: Pick<HppData, 'firstName' | 'lastName' | 'businessName' | 'userEmail'>
  ) => {
    setIsSubmitting(true);
    setErrorMessage(undefined);

    try {
      const response = await ApiClient.getInstance(
        sessionToken
      ).updatePaymentSession({
        customer: {
          firstName: info.firstName,
          lastName: info.lastName,
          businessName: info.businessName,
          email: info.userEmail
        },
        pspSessionId: sessionToken
      });

      if (response.status === 'ok') {
        setIsSubmitting(false);
        return true;
      } else if (response.type === 'network' || response.status > 500)
        setErrorMessage(['UPDATE_HPP_SESSION_ERROR_NETWORK']);
      else if (response.status === 500)
        setErrorMessage([
          'UPDATE_HPP_SESSION_ERROR_INTERNAL',
          { id: response.traceId }
        ]);
      else
        setErrorMessage([
          'UPDATE_HPP_SESSION_ERROR_UNKNOWN',
          { id: response.traceId }
        ]);

      return false;
    } catch (error) {
      console.error('Failed to get update flow session with error', error);
      setErrorMessage(['UPDATE_HPP_SESSION_ERROR_UNKNOWN']);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isLoading,
    isSubmitting,
    errorMessage,
    agencyToken,
    updateCustomerInfo,
    pspSession
  };
};

export const CheckoutStore = createContainer(useCheckoutSession);
export const useCheckoutStore = () => CheckoutStore.useContainer();
